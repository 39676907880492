<template>
  <CCard no-header>
    <CCardBody>
      <h3>
        <CIcon name="cil-pencil" /> {{ operationName }} Payment Method
      </h3>
      <CAlert v-if="message" :color="alertType"> {{ message }} </CAlert>
      <CRow>
        <CCol>
          <h3>General</h3>
        </CCol>
        <CCol class="text-right">
          <div><label>Active</label></div>
          <CSwitch color="success" size="lg" :checked.sync="paymentMethod.active">
          </CSwitch>
        </CCol>
      </CRow>
      <CRow>

        <CCol style="min-width: 10rem;">
          <label style="width: 95%">Name
            <a @click="toggleNameTranslations = !toggleNameTranslations">
              <CIcon name="cil-globe-alt" style="margin-bottom: 5px" class="text-primary"></CIcon>
            </a>
          </label>

          <div class="form-group">
            <div class="input-group" v-if="paymentMethod.nameTranslations[0]" v-show="!toggleNameTranslations">
              <div class="input-group-prepend">
                <span v-text="paymentMethod.nameTranslations[0].languageCode" class="input-group-text"
                  v-show="!toggleNameTranslations" />
              </div>
              <input :disabled.sync="paymentMethod.type != 'PayLater' && paymentMethod.type != 'StripeLink'" id="name" name="name" type="text"
                class="form-control" v-model="paymentMethod.nameTranslations[0].name" />
            </div>
          </div>
          <div class="form-group" v-show="toggleNameTranslations" v-for="(language, k) in paymentMethod.nameTranslations"
            :key="k">
            <div class="input-group">
              <div class="input-group-prepend">
                <span v-text="language.languageCode" class="input-group-text" />
              </div>
              <input :disabled.sync="paymentMethod.type != 'PayLater' && paymentMethod.type != 'StripeLink' " type="text" class="form-control"
                @id="language.languageCode" v-model="paymentMethod.nameTranslations[k].name" />
            </div>
          </div>
        </CCol>

      </CRow>

      <CRow>

        <CCol style="min-width: 10rem;">
          <label style="width: 95%">Terms
            <a @click="toggleTermsTranslation = !toggleTermsTranslation">
              <CIcon name="cil-globe-alt" style="margin-bottom: 5px" class="text-primary"></CIcon>
            </a>
          </label>

          <div class="form-group">
            <div class="input-group" v-if="paymentMethod.termsTranslation[0]" v-show="!toggleTermsTranslation">
              <div class="input-group-prepend">
                <span v-text="paymentMethod.termsTranslation[0].languageCode" class="input-group-text"
                  v-show="!toggleTermsTranslation" />
              </div>
              <textarea :disabled.sync="paymentMethod.type != 'PayLater'  && paymentMethod.type != 'StripeLink'" type="text" class="form-control"
                style="min-height:150px!important" v-model="paymentMethod.termsTranslation[0].name" />
            </div>
          </div>
          <div class="form-group" v-show="toggleTermsTranslation" v-for="(language, k) in paymentMethod.termsTranslation"
            :key="k">
            <div class="input-group">
              <div class="input-group-prepend">
                <span v-text="language.languageCode" class="input-group-text" />
              </div>
              <textarea :disabled.sync="paymentMethod.type != 'PayLater'  && paymentMethod.type != 'StripeLink'" @id="language.languageCode" name="name"
                type="text" class="form-control" style="min-height:150px!important"
                v-model="paymentMethod.termsTranslation[k].name" />
            </div>
          </div>
        </CCol>

      </CRow>


      <CRow>
        <CCol col="4">
          <CSelect label="Type" :value.sync="paymentMethod.type" :plain="true" :options="allowedPaymentMethods">
          </CSelect>
        </CCol>
      </CRow>

      <CRow>
        <CCol col="1">
          <CInput label="Sort Order" type="text" v-model="paymentMethod.sortOrder" />
        </CCol>
      </CRow>
      <CRow v-if="paymentMethod.type == 'StripeLink'">
        <CCol col="2">
          <CInput label="Expire unpaid reservation after (x) minutes" type="text" v-model="paymentMethod.cancelUnpaidBookingMinutes" />
        </CCol>
      </CRow>
      <CRow>
        <CCol col="1">
          <CInputCheckbox label="Hide by default" :checked.sync="paymentMethod.hideByDefault" />

        </CCol>
      </CRow>
      <h3>Currencies and Order Totals</h3>
      <CRow>
        <CCol style="margin-left:1vw!important" col="3" v-for="(currency, index) in currencies">
          <CRow>
            <CInputCheckbox :label="currency.name" :id="'currency-' + currency.currencyCode" />
          </CRow>
          <CRow>
            <label :for="'currencyMinOT-' + currency.currencyCode"><b>Minimum Order Total</b></label>
            <input class="form-control" min="0" :id="'currencyMinOT-' + currency.currencyCode" type="number" />
          </CRow>
          <CRow>
            <label :for="'currencyMaxOT-' + currency.currencyCode"><b>Maximum Order Total</b></label>
            <input class="form-control" min="0" :id="'currencyMaxOT-' + currency.currencyCode" type="number" />
          </CRow>
        </CCol>
      </CRow>
      <CRow>
        <CCol col="6"></CCol>
        <CCol class="d-flex justify-content-end">
          <CButton color="secondary" class="btn-lg" @click="goBack">
            <CIcon name="cil-list" /> Back to the list
          </CButton>

          <CButton color="primary" @click="save()" class="btn-lg" style="margin-left: 15px">
            <CIcon name="cil-save" /> Save
          </CButton>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>
  
<script>
import axios from "axios";
export default {
  name: "AddNewTaxClass",
  data: () => {
    return {
      toggleNameTranslations: false,
      toggleTermsTranslation: false,
      operationName: "Create New",
      partnerId: localStorage.getItem("partner_id"),
      emptyGuid: "00000000-0000-0000-0000-000000000000",
      token: localStorage.getItem("api_token"),
      languages: [],
      taxClasses: [],
      currencies: [],
      allowedPaymentMethods: [],
      // Alert
      alertType: "danger",
      message: null,

      paymentMethod: {
        id: "00000000-0000-0000-0000-000000000000",
        nameTranslations: [],
        termsTranslation: [],
        newBookingStatus: null,
        sortOrder: 0,
        cancelUnpaidBookingMinutes: 0,
        hideByDefault: false,
        currencyId: null,
        currencyList: null,
        taxClassId: null,
        currencyList: [],
        paymentMethodsType: null,
        active: true,
      },
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },

    getCurrencies() {
      let self = this;
      axios
        .get(this.$apiAdress + "/v1/PartnerCurrency")
        .then(function (response) {
          response.data.map(function (value, key) {
            value.name = value.name;
            value.currencyId = value.currencyId;
            value.currencyCode = value.currencyCode;
            value.checked = false;
            self.currencies.push(value);
          });
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    getLanguages() {
      let self = this;
      axios
        .get(`${this.$apiAdress}/v1/PartnerLanguage`)
        .then(function (response) {
          self.languages = response.data;
          for (let i = 0; i < self.languages.length; i++) {
            self.languages = response.data;
            if (self.paymentMethod.id == self.emptyGuid) {
              for (let i = 0; i < self.languages.length; i++) {
                if (self.paymentMethod.nameTranslations.find(x => x.languageCode == self.languages[i].languageCode) == undefined) {
                  self.paymentMethod.nameTranslations.push({
                    languageCode: self.languages[i].languageCode,
                    name: null,
                    languageId: self.languages[i].languageId,
                  });
                }
                if (self.paymentMethod.termsTranslation.find(x => x.languageCode == self.languages[i].languageCode) == undefined) {
                  self.paymentMethod.termsTranslation.push({
                    languageCode: self.languages[i].languageCode,
                    name: null,
                    languageId: self.languages[i].languageId,
                  });
                }

              }
            }
            else {

            }
          }

        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    get(id) {
      let self = this;
      axios
        .get(`${this.$apiAdress}/v1/PartnerPaymentMethods/${id}`)
        .then(function (response) {
          self.paymentMethod = response.data;
          if (self.paymentMethod.paymentMethodsType == 1) {
            self.paymentMethod.type = "StripeCard";
          }
          if (self.paymentMethod.paymentMethodsType == 2) {
            self.paymentMethod.type = "StripeKlarna";
          }
          if (self.paymentMethod.paymentMethodsType == 3) {
            self.paymentMethod.type = "PayLater";
          }
          if (self.paymentMethod.paymentMethodsType == 4) {
            self.paymentMethod.type = "StripeIdeal";
          }
          if (self.paymentMethod.paymentMethodsType == 5) {
            self.paymentMethod.type = "StripeLink";
          }
          for (let i = 0; i < self.languages.length; i++) {
            let test = self.paymentMethod.nameTranslations;
            self.paymentMethod.nameTranslations = [];
            test.map(function (value, key) {
              var exists = self.languages.find(x => x.languageCode == value.languageCode);
              if (exists != undefined) {
                self.paymentMethod.nameTranslations.push(value);
              }
            });
            let termsTranslationTest = self.paymentMethod.termsTranslation;
            self.paymentMethod.termsTranslation = [];
            termsTranslationTest.map(function (value, key) {
              var exists = self.languages.find(x => x.languageCode == value.languageCode);
              if (exists != undefined) {
                self.paymentMethod.termsTranslation.push(value);
              }
            });
          }
          self.$nextTick(() => {
            self.paymentMethod.currencyList.forEach((item, index) => {
              document.getElementById("currency-" + item.currencyCode).checked = true;
              document.getElementById("currencyMinOT-" + item.currencyCode).value = item.minimumOrderTotal;
              document.getElementById("currencyMaxOT-" + item.currencyCode).value = item.maximumOrderTotal;
            });
          });

        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    save() {
      let self = this;
      let form = new FormData();
      form.append("id", self.paymentMethod.id);
      form.append("sortOrder", self.paymentMethod.sortOrder);
      form.append("hideByDefault", self.paymentMethod.hideByDefault);
      form.append("active", self.paymentMethod.active);
      form.append("cancelUnpaidBookingMinutes", self.paymentMethod.cancelUnpaidBookingMinutes ?? 0);
      form.append("nameTranslationsJson", JSON.stringify(self.paymentMethod.nameTranslations));
      form.append("termsTranslationJson", JSON.stringify(self.paymentMethod.termsTranslation));
      if (self.paymentMethod.type == "PayLater") {
        form.append("paymentMethodsType", 3);

      }

      if (self.paymentMethod.type == "StripeCard") {
        form.append("paymentMethodsType", 1);
      }

      if (self.paymentMethod.type == "StripeKlarna") {
        form.append("paymentMethodsType", 2);
      }
      if (self.paymentMethod.type == "StripeIdeal") {
        form.append("paymentMethodsType", 4);
      }
      if (self.paymentMethod.type == "StripeLink") {
        form.append("paymentMethodsType", 5);
      }
      self.paymentMethod.currencyList = [];
      self.currencies.forEach((item, index) => {

        if (document.getElementById("currency-" + item.currencyCode).checked == true) {
          var value = item;
          value.id = item.id;
          value.currencyId = item.currencyId;
          value.currencyCode = item.currencyCode;
          value.minimumOrderTotal = document.getElementById("currencyMinOT-" + item.currencyCode).value;
          value.maximumOrderTotal = document.getElementById("currencyMaxOT-" + item.currencyCode).value;
          self.paymentMethod.currencyList.push(value);
        }
      });
      form.append("currencyListJson", JSON.stringify(self.paymentMethod.currencyList));
      axios
        .post(`${this.$apiAdress}/v1/PartnerPaymentMethods`, form)
        .then((response) => {
          self.paymentMethod.id = response.data;
                    self.alertType = "success";
          setTimeout(function () {
              self.message = null;
          }, 5000);
          self.message = "Successfully created payment method class.";
          self.paymentMethod.currencyList = [];
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error.response.data;
        });
    },

    getAllowedPaymentMethods() {
      let self = this;
      axios
        .get(`${this.$apiAdress}/v1/PartnerPaymentMethods/AllowedPaymentMethods`)
        .then(function (response) {
          self.allowedPaymentMethods = response.data;
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });

    }
  },
  mounted: function () {
    this.getCurrencies();
    this.getLanguages();
    this.getAllowedPaymentMethods();
    if (this.$route.params.id != this.emptyGuid) {
      this.operationName = "Edit";
      this.get(this.$route.params.id);
    }
  },
};
</script>
  